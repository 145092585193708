@import 'utils';

.scenarios-container {
  background: #ffffff;
  height: 80vh;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgba(#888888, 0.2);
  position: relative;

  .filters-container {
    background: #ffffff;
  }

  footer {
    @apply px-3 py-2 flex justify-between items-center;

    position: absolute;
    bottom: 0;
    border-top: 1px solid #dee2e6;
    width: 100%;
    background: white;
  }

  .table> :not(caption)>*>* {
    padding: 13px 16px;
  }
}

.scenarios-table {
  height: 67vh;
  overflow-y: auto;
  position: relative;

  thead tr td {
    position: sticky;
    top: 0;
    background-color: #ffffff;
  }
}

.table-title {
  width: 36rem;
  z-index: 1;
}

.season {
  width: 10rem;
}

.solve-type-box {
  display: flex;
  justify-content: space-between;

  margin-top: 10px;
  border: 1px solid $radioButtonBoxBorderColor;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-color: $disabledBackground;
  }

  &.active {
    border: 3px solid $primaryBackgroundColor;
  }
}