@import 'utils';

$sidebar-close-width: 40px;
$sidebar-open-width: 200px;
$results-width: 200px;

.scenario-detail-container {
  height: calc(100vh - 64px);
  position: relative;

  @apply flex flex-col justify-between;

  .scenario-content {
    height: 84vh;

    @apply flex grow;

    .results-sidebar {
      width: $sidebar-close-width;
      transition: width 0.2s ease-in-out;

      @apply relative px-2 flex justify-end;

      .results {
        width: $results-width;
        top: 0;
        left: -$results-width;
        max-height: 100%;
        overflow-y: auto;

        transition: left 0.2s ease-in-out;

        @apply absolute px-2 pb-3 pt-1;

        &.open {
          left: 0;
        }
      }

      .trigger {
        position: absolute;
        top: 2px;
        right: 6px;
        background-color: transparent;
      }

      &.open {
        width: $sidebar-open-width;

        .trigger {
          right: 9px;
          background-color: $secondaryBackgroundColor;
          border: 1px solid $drawerBorder;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-right: 1px solid transparent;
        }
      }
    }

    .schedule-container {
      border-left: 1px solid #dee2e6;
      @apply grow flex;

      .round-teams-grid {
        width: 900px;

        @apply relative grow h-full flex overflow-hidden whitespace-nowrap;

        .ant-spin-nested-loading {
          width: 100%;

          .ant-spin-container {
            height: 100%;
          }
        }
      }

      .resize {
        border: 1px solid red;
      }
    }
  }

  .penalty-table {
    width: auto;

    tr {
      td {
        &:last-child {
          width: 100%;
        }
      }
    }
  }

  footer {
    @apply px-4 py-4 flex justify-center items-center;

    > * {
      flex-grow: 1;
    }

    // position: absolute;
    // bottom: 0;
    border-top: 1px solid #dee2e6;
    width: 100%;

    .scenario-actions {
      @apply flex justify-center items-center gap-2;

      .penalty-button {
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--Base-White, #fff);
        color: var(--Gray-700, #344054);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }
}

.icon {
  cursor: pointer;
}

.divider {
  border-inline-start: 1px solid rgba($textColor1, 0.3);
  height: 16px;
}

.view-log-section {
  max-height: 1000px;
  overflow-y: auto;
}
