@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  background: #fcfcfc;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.btn-default {
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800;
}

.toggle-default {
  background-color: #fcfcfc;
}

.dropdown {
  button {
    @extend .btn-default;
  }
}

.ant-btn-primary {
  @extend .btn-default;
}

.create-btn {
  color: #4096ff;
  border-color: #4096ff;
  background: #ffffff;

  @apply flex items-center;

  svg {
    width: 18px;
  }
}

.default-pop-over {
  .ant-card-head {
    min-height: 40px;
  }
  .ant-card-body {
    padding: 16px 0;
  }

  &.flat {
    .ant-card-body {
      padding: 0;
    }
  }
}

.table-cell-popover {
  .ant-card-body {
    padding: 0.5rem !important;
    font-size: 12px;
  }
}
