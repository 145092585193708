@import 'utils';

.slots-group {
  display: flex;
  height: 95%;
  flex-direction: column;
  font-size: $defaultSize;
  margin: 0 -12px;

  .divider {
    margin: 10px 0;
  }

  .pills-tab {
    display: flex;
    padding: 0 16px;

    .pill-item {
      padding: 4px 16px;
      border-top: 1px solid $tableBorder;
      border-bottom: 1px solid $tableBorder;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &.active {
        background-color: $bgColor2;
      }

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border: 1px solid $tableBorder;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:not(:last-child) {
        border-left: 1px solid $tableBorder;
      }
    }
  }

  .networks-tab {
    display: flex;
    height: 100%;

    > * + * {
      margin-left: 0.5rem;
    }

    .slot-section {
      flex-basis: 30%;
    }

    .network-section {
      flex-grow: 1;
    }
  }

  .slots-tab {
    display: flex;
    height: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    > * + * {
      margin-left: 0.5rem;
    }

    .slot-section {
      flex-basis: 75%;
      flex-grow: 1;
      height: 100%;
      overflow-y: hidden;

      .slot-list {
        height: 95%;
        overflow-y: auto;

        .slots-summary:first-child {
          .slot-round {
            border-top: 1px solid $drawerBorder;
          }
        }

        .slot-round {
          background: $white;
          border-bottom: 1px solid $drawerBorder;
          box-shadow: none;
          width: 180px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          .title {
            padding: 0.85rem 0.75rem;
          }

          .name {
            padding: 1rem 0.75rem;
            color: $textColor4;

            .date {
              color: $textColor2;
            }
          }

          :first-of-type {
            border-bottom: none;
          }
        }

        .calender {
          .fc-daygrid-day-number {
            color: $textColor5;
          }
        }
      }
    }

    .network-section {
      flex-basis: 20%;
    }
  }

  .slots-summary {
    display: flex;

    &.sticky {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0;
      z-index: 9999;
    }

    .list {
      flex-basis: 20%;

      .name {
        color: $textColor1;
        line-height: 1.8;
      }
      .date {
        color: $textColor2;
      }
    }

    .calender-columns {
      display: flex;
      flex-grow: 1;
      border-bottom: 1px solid $drawerBorder;

      .week-day {
        width: 100%;
        display: grid;
        place-content: center;
        padding: 6px 12px;
        border-top: 1px solid $drawerBorder;
        border-right: 1px solid $drawerBorder;
        background: $bgColor1;
        font-weight: 500;

        &:first-of-type {
          border-left: 1px solid $drawerBorder;
        }
      }
    }

    .calender {
      flex-grow: 1;

      &.no-body {
        tbody {
          td {
            height: 0 !important;

            .fc-scroller-harness-liquid {
              height: 0 !important;
            }
          }
        }
      }

      .fc-header-toolbar {
        display: none;
      }

      .fc-col-header-cell {
        padding-top: 12px;
        padding-bottom: 11px;
        background-color: $bgColor1;
      }

      td.fc-day {
        &.active-round-day {
          background: $white;
        }

        &.inactive-cell {
          pointer-events: none;
          cursor: not-allowed;
        }
      }

      .fc-daygrid-day-events {
        max-height: 100px;
        overflow-y: auto;
      }

      &.hide-invalid-event {
        .fc-daygrid-event-harness {
          &:has(.invalid-event) {
            position: absolute !important;
            top: -9999px !important;
            left: -9999px !important;
          }
        }
      }
    }

    &.hidden-header {
      .ag-header,
      .fc-scrollgrid > thead {
        display: none;
      }
    }
  }

  .round-tab {
    .round-start-edit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 9rem;
      padding: 0.5rem;
      background: $white;
      color: $textColor6;
      border-radius: 4px;
      pointer-events: none;

      &.active {
        pointer-events: all;
        cursor: pointer;
      }
    }
    .calender {
      .fc-header-toolbar {
        display: none;
      }

      .fc-col-header-cell {
        padding-top: 12px;
        padding-bottom: 11px;
        background-color: $bgColor1;
        position: relative;

        .pin {
          position: absolute;
          top: 12px;
          left: 4px;
          padding: 2px;
          cursor: pointer;
          display: none;
        }

        &:hover {
          .pin {
            display: inline;
          }
        }
      }

      .round-calendar-cell {
        > div {
          min-height: 4.5rem !important;

          .fc-event.round-calendar-event {
            background-color: $cellSelectionBgClr !important;
            border-color: $cellSelectionBgClr !important;
            border-radius: 8px;

            .fc-event-main {
              position: relative;

              color: $textColor3 !important;

              padding: 4px;

              .event-actions {
                display: none;
              }

              &:hover {
                .event-actions {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  span {
                    cursor: pointer !important;
                  }
                }
              }
            }
          }
        }

        .fc-daygrid-day-number {
          color: $textColor5;
        }
      }

      .fc-day-today {
        background: $white;
      }
    }
  }
}
