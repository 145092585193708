@import 'utils';

.opponents-grid {
  width: auto;
  background: $drawerHeaderBackground;
  height: 100%;
  font-size: $defaultSize;
  overflow-y: auto;

  @apply relative my-1 px-1 py-3 overflow-x-auto shadow-md sm:rounded-lg;
}

.opponents-content {
  background: $white;
  flex: 1;
  overflow-y: auto;

  @apply relative overflow-x-auto;

  & > :not(:first-child) {
    border-top: none;
  }

  td {
    padding: 0;
    width: 4.5rem;
    height: 3.5rem;
    border-color: $tableBorder;
    border-left: none;
    position: relative;

    &.opp-disabled {
      background: $tableBorder;
    }

    &.result-item {
      background: $bgColor1;
      text-align: center;
      padding-top: 16px;
    }

    .inactive-input {
      display: none !important;
      z-index: -1;
    }

    .active-input {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4.5rem;
      height: 3.5rem;
      text-align: center;
    }
  }

  .opponent {
    .hidden {
      display: none !important;
    }

    &.invalid {
      background-color: $errorColor;
    }

    .ant-dropdown-trigger {
      @apply flex justify-center items-center;
    }

    &:focus {
      background-color: $cellSelectionBgClr;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.opponents-wrapper {
  max-width: 100%;
  max-height: 900px;
  overflow: auto;
}

.opponents-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;

  .opponents-header {
    flex-grow: 1;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: flex-start;
    background: $bgColor1;
    border-bottom: 2px solid $tableHeaderBorder;
    border-top-left-radius: 12px;

    .empty {
      min-width: 95px;
      cursor: pointer;
      height: 100%;

      transition: background-color 0.3s ease-in-out;

      .swap {
        padding: 8px;
        display: none;
      }

      &:hover {
        background-color: $cellSelectionBgClr;
        border-color: $cellSelectionBgClr;

        .swap {
          display: inline-block;
        }
      }
    }

    .teams {
      flex-grow: 1;
      border-left: 1px solid $tableHeaderBorder;

      display: flex;
      // flex-wrap: wrap;

      .teams-wrapper {
        border-right: 1px solid $tableBorder;
      }

      .header {
        width: 100%;
        flex-grow: 1;
        height: 2rem;
        padding-left: 1.6rem;
        padding-top: 0.25rem;
      }

      .team-list {
        width: 100%;
        flex-grow: 1;
        display: flex;

        &-item {
          width: 4.5rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid $tableBorder;
          border-top: 1px solid $tableBorder;

          &:last-of-type {
            border-right: none;
          }

          &.active {
            background-color: $cellSelectionBgClr;
            border-color: $cellSelectionBgClr;
          }
        }
      }

      .total {
        border-right: 1px solid $tableBorder;
        width: 4.5rem;

        @apply flex justify-center items-center;
      }
    }
  }

  .opponents-sidebar {
    width: 6rem;
    background: $bgColor1;
    display: flex;
    flex-wrap: wrap;

    .header {
      width: 48px;
      position: relative;

      span {
        position: absolute;
        top: 20px;
        left: -34px;
        transform: translateY(24px) rotate(-90deg);
        width: 114px;
      }
    }

    .team-list {
      display: flex;
      flex-direction: column;

      .team-list-item {
        width: 3rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $tableBorder;

        &.active {
          background-color: $cellSelectionBgClr;
          border-color: $cellSelectionBgClr;
        }

        &:first-of-type {
          border-top: none;
        }

        &:last-of-type {
          border-bottom: none;
        }

        &.top:first-of-type {
          width: 72px;
        }

        &.top:last-of-type {
          width: 72px;
        }
      }
    }

    .total {
      height: 3.5rem;
      border-top: 1px solid $tableBorder;
      border-right: 1px solid $tableBorder;
      flex-grow: 1;
      @apply flex justify-center items-center py-3;
    }
  }

  .opponent-table {
    width: auto;
  }

  .opponents-content .table {
    margin-bottom: 0;

    &.no-action {
      pointer-events: none;
    }

    tbody {
      border-top: 1px solid rggb($white, 0.5);
    }

    .opponent.highlighted {
      background-color: $cellSelectionBgClr;
    }
  }
}

.opponent-popover {
  background: white;
  border: 1px solid $tableBorder;
  width: 150px;
  border-radius: 6px;

  .ant-divider {
    margin: 6px 0 !important;
  }

  &-header {
    padding: 6px 6px 0 6px;
    cursor: pointer;
  }

  &-options {
    padding: 0 2px;

    & > * {
      cursor: pointer;
      padding: 6px 4px;
    }
  }

  &-footer {
    padding: 0 6px 6px 6px;
    @apply flex justify-between items-center;
  }
}

.opponent-header-actions {
  @apply flex items-center gap-2;

  .penalty {
    @apply flex items-center gap-2;
  }

  .patterns {
    @apply flex items-center gap-3;

    background: $cellSelectionBgClr;
    border-radius: 4px;
    padding: 4px;

    .item {
      cursor: pointer;
      padding: 0 10px;
      border-radius: 5px;
      transition: all 0.4s ease-in-out;
      color: #667085;

      &.active {
        background: white;
        color: black;
      }
    }
  }

  .divider {
    border-inline-start: 1px solid rgba($textColor1, 0.3);
    height: 16px;
  }
}
