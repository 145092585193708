@import 'utils';

.penalty-table {
  width: auto;
  background: $drawerHeaderBackground;
  height: 100%;
  overflow-y: hidden;

  .penalty-badge {
    border-radius: 16px;
    mix-blend-mode: multiply;
    margin-left: 10px;

    .ant-scroll-number {
      color: var(--Gray-700, #344054);
    }
  }

  @apply relative my-2 px-1 py-3 overflow-x-auto shadow-md sm:rounded-lg;

  .table-header {
    background-color: $bgColor1;

    tr:first-child th:first-child {
      border-top-left-radius: 0.75rem;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 0.75rem;
    }
  }
}
