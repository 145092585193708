$primaryBackgroundColor: #1570ef;
$secondaryBackgroundColor: #f9fafb;

$drawerHeaderBackground: #f3f2f2;
$drawerBorder: #dee2e6;
$white: #ffffff;
$textColor1: #0f1316;
$textColor2: #475467;
$textColor3: #175cd3;
$textColor4: #101828;
$textColor5: #98a2b3;
$textColor6: #667085;

$bgColor1: #f9fafb;
$bgColor2: #f2f4f7;
$tableBorder: #eaecf0;
$tableHeaderBorder: #d0d5dd;
$cellSelectionClr: #2e90fa;
$cellSelectionBgClr: #d1e9ff;
$warningBackgroundColor: #fef0c7;
$errorColor: #d41303;
$defaultSize: 14px;
$radioButtonBoxBorderColor: #ccc;
$disabledBackground: rgba($drawerHeaderBackground, 0.5);

* {
  scrollbar-width: thin;
}

.title-lg {
  color: $textColor1;
  font-weight: 500;
  font-size: 32px;
}

.section-title {
  color: $textColor1;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;
}

.section-subtitle {
  color: rgba($textColor1, 0.7);
}

.section-container {
  background-color: $bgColor2;
  padding: 10px 8px;
  border-radius: 6px;
}

.section-ag-grid {
  height: 89%;
}

.white-btn {
  background-color: $white !important;
  border-color: $cellSelectionBgClr;
  color: $textColor3;
  font-weight: 500;
}

.ag-header-cell.ag-header-center {
  .ag-header-cell-comp-wrapper {
    .ag-header-cell-label {
      justify-content: center !important;
    }
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-center {
  display: grid !important;
  place-content: center !important;
}

.ag-grid-border {
  .ag-root-wrapper {
    border: 1px solid $tableBorder;
    border-left-color: $white !important;
    border-right-color: $white !important;
  }

  .ag-ltr .ag-cell:not(:last-child) {
    border-right: 1px solid $tableBorder;
  }
}

.ant-color-picker-trigger {
  min-width: 44px;
  height: 22px;
  padding: 0;
  border: none;

  .ant-color-picker-color-block {
    height: 18px;
    width: 42px;
  }
}

.ag-grid-headless {
  .ag-root .ag-header {
    display: none;
  }
}

.ag-row-odd {
  background-color: $white !important;
}

.secondary-text {
  color: $textColor2 !important;
}

.primary-background {
  background: $primaryBackgroundColor !important;
}

.secondary-background {
  background: $secondaryBackgroundColor !important;
}

.white-background {
  background-color: $white !important;
}

.list-section {
  background-color: $white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @apply shadow-md;

  .title {
    background-color: $secondaryBackgroundColor;
    padding: 1rem 0.75rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    font-weight: 500;
    border-bottom: 1px solid $tableHeaderBorder;
  }
}

.warning-background {
  background: $warningBackgroundColor;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid $warningBackgroundColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.white-background {
  background: $white;
}

.ag-grid-white {
  &.no-x-border {
    .ag-root-wrapper {
      border: 0px !important;
    }
  }

  .ag-header-row {
    background-color: $white;
  }
}

.ag-grid-curve {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  // &.white {
  //   .ag-header-row {
  //     background-color: $white;
  //   }
  // }

  .ag-root-wrapper {
    border-radius: 6px;
  }

  .ag-ltr .ag-cell {
    &.ag-cell-inline-editing {
      .ant-dropdown-trigger,
      .ant-select {
        padding-left: 1rem !important;
      }
    }
  }

  .ag-grid-right-header-aligned {
    .ag-header-cell-label {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
}

.h-95 {
  height: 95%;
}

.no-border {
  &.ant-select {
    .ant-select-selector {
      border: none;
    }
  }
}

.minimal-modal {
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 16px;
  }

  .ant-tabs-nav {
    padding: 0 16px;
  }
}

.ant-width {
  .ant-picker,
  .ant-select,
  .ant-input-affix-wrapper {
    width: 150px;
  }
}

div[data-reach-combobox-popover] {
  position: fixed !important;
  z-index: 1000;
}

.table-cell {
  color: $textColor5;
}

.read-only {
  pointer-events: none;
}

.carret-down {
  color: rgba(0, 0, 0, 0.25);
  height: 12px;
  font-size: 12px;
}

.slot-event-title {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.processing {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $textColor3;
  animation: blink 0.5s linear infinite;
}

.queue-service-item {
  &.ant-list-item {
    font-size: 12px;
    padding-block: 8px !important;
    display: flex;
    gap: 1rem;
    align-items: center;
    border-radius: 4px;
  }

  &.active {
    background: $secondaryBackgroundColor;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.clone-icon {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    left: 12px;
    top: 12px;
    content: '';
    background: url('../public/assets/icons/copy.svg');
    width: 20px;
    height: 20px;
  }
}

.trash-icon {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    left: 12px;
    top: 12px;
    content: '';
    background: url('../public/assets/icons/trash.svg');
    width: 20px;
    height: 20px;
  }
}

.edit-icon {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    left: 12px;
    top: 12px;
    content: '';
    background: url('../public/assets/icons/edit.svg');
    width: 20px;
    height: 20px;
  }
}

.terminal-log {
  code {
    min-width: 100% !important;
    padding-bottom: 30px !important;
    height: calc(100vh - 150px) !important;

    .linenumber {
      min-width: 3em !important;
    }
  }
}

.primary-color-text {
  color: $textColor1;
}

.no-border-btn {
  border-color: transparent;

  &:hover {
    border-color: transparent !important;
  }
}

.flex-column {
  flex-direction: column !important;
}

.pointer-arrow {
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    left: 18px;
    top: 10px;
    content: '';
    background: url('../public/assets/icons/corner-down-right.svg');
    width: 20px;
    height: 20px;
  }
}

.provider-collapse {
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .collapse-footer {
    background: rgb(247, 247, 247);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.btn-action {
  background: rgb(209, 234, 255) !important;
  color: $textColor3 !important;
  font-weight: 500 !important;
}

.ant-dropdown-menu.ant-dropdown-menu-sub {
  max-height: 450px;
  overflow: auto;
}
