@import 'utils';

.app-solutions {
  margin-top: 1rem;
  background: inherit;

  .ant-collapse-item {
    border-bottom: 0;

    .ant-collapse-header {
      padding: 0.5rem;
      color: inherit;

      &:hover {
        .ant-collapse-extra {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    .ant-collapse-content {
      color: inherit;
    }

    .ant-collapse-content-box {
      padding-inline: 6px;
      color: inherit;
    }
  }

  .solution-item {
    padding: 0.2rem 0.75rem;
    font-weight: 600;
    font-size: small;
    cursor: pointer;
    border-radius: 3px;
  }
}
