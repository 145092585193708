@import 'utils';

.network-categories {
  margin-top: 16px;
  max-height: 600px;
  overflow-y: auto;

  > * + * {
    margin-top: 12px;
  }
}

.network-category-group {
  background: white;
  border-radius: 8px;

  .header {
    background-color: $bgColor1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
      padding: 12px;
    }

    .slot {
      width: 180px;
      text-align: center;
      align-self: normal;
      border-left: 1px solid $tableBorder;
      border-right: 1px solid $tableBorder;

      @apply grid-center;
    }

    .color {
      width: 131px;
      text-align: center;
      align-self: normal;
      border-left: 1px solid $tableBorder;
      border-right: 1px solid $tableBorder;

      @apply grid-center;

      span {
        width: 42px;
        height: 18px;
        border-radius: 4px;
      }
    }

    .action {
      width: 101px;
      text-align: center;
      align-self: normal;
      @apply grid-center;
    }
  }
}

.network-list {
  max-height: 576px;
  overflow-y: auto;

  &-item {
    border-bottom: 1px solid $drawerBorder;
    padding: 0.75rem 0.5rem;
    cursor: pointer;

    &.fc-event-dragging {
      border-bottom: none;

      .ant-btn {
        display: none;
      }
    }
  }
}
