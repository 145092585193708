@import 'utils';

.venueteams-grid {
  width: auto;
  // background: $drawerHeaderBackground;
  height: 100%;
  font-size: $defaultSize;
  overflow-y: auto;

  @apply relative my-2 px-0 py-3 overflow-x-auto sm:rounded-lg;
}

.venueteams-content {
  background: $white;
  flex: 1;
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @apply relative px-0 overflow-x-auto shadow-md sm:rounded-lg;

  & > :not(:first-child) {
    border-top: none;
  }

  .highlight {
    background: $tableBorder !important;
  }

  tr.sticky {
    background-color: $secondaryBackgroundColor;
  }

  td {
    width: 4.5rem;
    height: 2.9rem;
    border-color: $tableBorder;
    text-align: center;
    padding-top: 12px !important;

    &.checked {
      background: $primaryBackgroundColor;
    }
  }
}

.venueteams-venue-header {
  writing-mode: vertical-rl;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.venue-teams-editor-venue-name {
  width: fit-content;
  white-space: nowrap;
}

.venue-teams-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;

  .venue-teams-wrapper {
    display: flex;

    .empty {
      min-width: 6rem;
      min-height: 6rem;
      background: $bgColor1;
      border-bottom: 2px solid $tableHeaderBorder;
      border-top-left-radius: 12px;
    }
  }

  .venue-teams-sidebar {
    width: 6rem;
    background: $bgColor1;
    display: flex;

    .header {
      width: 48px;
      position: relative;

      span {
        position: absolute;
        transform: translateY(24px) rotate(-90deg);
      }
    }

    .venue-list {
      display: flex;
      flex-direction: column;

      .venue-list-item {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $tableBorder;

        &:first-of-type {
          border-top: none;
        }
      }
    }
  }

  .venue-teams-content {
    flex-grow: 1;

    .table {
      margin-bottom: 0;

      tbody {
        border-top: 1px solid $white;
      }
    }
  }
}
