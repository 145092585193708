.app-user {
  position: relative;

  .btn-abbr {
    background: aliceblue;
    color: gray;
    outline: 0;
    border: 1px solid aliceblue;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    &:focus {
      outline: 0;
    }
  }

  .navigation {
    &-items {
      min-width: 10rem;
      position: absolute;
      top: 40px;
      left: -128px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;

      &.show {
        visibility: visible;
        opacity: 1;
        z-index: 2;
      }

      border: 1px solid rgba(#000000, 0.2);
      border-radius: 4px;
    }

    &-item {
      background: white;
      color: black;
      padding: 0.5rem 0.6rem;
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid rgba(#000000, 0.2);

      &:hover {
        background: #f9f9f9;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
