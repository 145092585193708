@import 'utils';

$zoom_90_width: 4.3rem;
$zoom_90_height: 2rem;
$zoom_90_font_size: 13px;

$zoom_80_width: 3.9rem;
$zoom_80_height: 1.7rem;
$zoom_80_font_size: 13px;

$zoom_70_width: 3.4rem;
$zoom_70_height: 1.4rem;
$zoom_70_font_size: 12px;

$zoom_60_width: 2.9rem;
$zoom_60_height: 1.2rem;
$zoom_60_font_size: 11px;

$zoom_50_width: 2.4rem;
$zoom_50_height: 1rem;
$zoom_50_font_size: 10px;

.solve-table {
  border: 1px solid black;
  font-size: 15px;
  overflow-y: auto;
  width: auto !important;

  & > :not(:first-child) {
    border-top: none;
  }

  thead {
    border-color: $bgColor2;
  }

  th {
    background: $bgColor2;

    &:first-child {
      border-right: 3px solid #eaecf0;
    }

    &.active {
      background-color: $cellSelectionBgClr !important;
    }
  }

  th,
  td {
    height: 2.3rem;
    min-width: 4.8rem;
    border-color: rgba(#eaecf0, 1);
    text-align: center;

    &.active {
      background-color: $cellSelectionBgClr !important;
    }

    &.zoom-90 {
      font-size: $zoom_90_font_size;
      height: $zoom_90_height;

      &-cell {
        height: $zoom_90_height;
        min-width: $zoom_90_width;
        font-size: $zoom_90_font_size;
      }
    }

    &.zoom-80 {
      font-size: $zoom_80_font_size;
      height: $zoom_80_height;

      &-cell {
        height: $zoom_80_height;
        min-width: $zoom_80_width;
        font-size: $zoom_80_font_size;
      }
    }

    &.zoom-70 {
      font-size: $zoom_70_font_size;
      height: $zoom_70_height;

      &-cell {
        height: $zoom_70_height;
        min-width: $zoom_70_width;
        font-size: $zoom_70_font_size;
      }
    }

    &.zoom-60 {
      font-size: $zoom_60_font_size;
      height: $zoom_60_height;

      &-cell {
        height: $zoom_60_height;
        min-width: $zoom_60_width;
        font-size: $zoom_60_font_size;
      }
    }

    &.zoom-50 {
      font-size: $zoom_50_font_size;
      height: $zoom_50_height;

      &-cell {
        height: $zoom_50_height;
        min-width: $zoom_50_width;
        font-size: $zoom_50_font_size;
      }
    }
  }

  tbody tr {
    border-color: rgba(#eaecf0, 1);

    td:first-child {
      border-right: 3px solid #eaecf0;
    }
  }

  tr > td:first-child,
  tr > th:first-child {
    width: 12rem;
  }
}

.solve-table .team-match.solve-table-cell-team-not-selected {
  background-color: white;
}

.solve-table {
  .team-match {
    &.highlighted {
      background-color: $cellSelectionBgClr !important;
      color: $cellSelectionClr !important;
    }

    &.unlocked {
      font-style: italic;
      font-weight: lighter;
      font-size: smaller;
      border-style: dashed !important;
      border: #6d17f8;
    }
  }
}

.solve-table .team-match .solve-table .saving-in-progress {
  background-color: #080808 !important;
}

.solution-solved {
  background-color: lightgrey;
}

.toggle-view {
  position: relative;

  .swap {
    position: absolute;
    top: 4px;
    left: 4px;

    opacity: 0;

    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: $cellSelectionBgClr;
    border-color: $cellSelectionBgClr;

    .swap {
      opacity: 1;
    }
  }
}
