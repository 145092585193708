@import 'utils';

.search-input {
  width: 100%;
  border-radius: 0;
  margin-top: 0 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.constraints-group {
  display: flex;
  height: 100%;
  flex-direction: column;
  font-size: $defaultSize;
  margin: 0 -12px;

  .divider {
    margin: 10px 0;
  }

  .pills-tab {
    display: flex;
    padding: 0 16px;

    .pill-item {
      padding: 4px 16px;
      border-top: 1px solid $tableBorder;
      border-bottom: 1px solid $tableBorder;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &.active {
        background-color: $bgColor2;
      }

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border: 1px solid $tableBorder;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &:not(:last-child) {
        border-left: 1px solid $tableBorder;
      }
    }
  }

  .constraint-table,
  .set-table,
  .setline-table {
    width: auto;
    background: $drawerHeaderBackground;
    height: 100%;
    overflow-y: hidden;

    @apply relative px-1 py-3 overflow-x-auto shadow-md sm:rounded-lg;

    .table-header {
      background-color: $bgColor1;

      tr:first-child th:first-child {
        border-top-left-radius: 0.75rem;
      }

      tr:first-child th:last-child {
        border-top-right-radius: 0.75rem;
      }
    }
  }
}

.set-definition {
  min-width: 850px;
}

.sets-section {
  height: 100%;
}
