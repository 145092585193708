@import 'utils';

.solve-data-table {
  .solve-info {
    @apply flex justify-between items-center my-1 mb-4 mx-3;

    .stats {
      @apply flex items-center gap-4;
      font-size: $defaultSize;
      font-weight: 400;
    }
  }
}
