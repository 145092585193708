@import 'utils';

.optimal-app-header {
  background: rgb(41, 45, 62, 1);
  display: flex;
  align-items: center;

  > * {
    flex-grow: 1;
  }

  .app-logo {
    color: white;
  }

  .header-left {
    @apply flex gap-8 items-center;

    .btn-back {
      width: 32px;
      height: 32px;
      border: 1px solid #e5e7eb;

      @apply flex justify-center items-center rounded-full cursor-pointer;
    }
  }

  .header-right {
    @apply flex justify-end items-center gap-3;

    .queue-holder {
      background: $secondaryBackgroundColor;
      border-radius: 2px;
    }
  }

  .scenarios-selector-container {
    @apply flex justify-center items-center;
    justify-self: center;

    .scenario-selected {
      min-width: 10rem;
      position: relative;
      z-index: 2;

      .selected {
        background: black;
        color: white;

        @apply px-3 py-1 rounded-md w-full flex justify-center items-center gap-2;
      }

      @apply cursor-pointer;

      [class$='-control'] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      [class$='-menu'] {
        margin-top: 0;
      }
      [class$='-MenuList'] {
        padding: 0;
      }
    }

    .scenario-options {
      position: absolute;
      top: 40px;
      right: 0;
    }
  }
}
