@import 'utils';

.menu {
  color: $textColor6;
  padding: 8px 60px 8px 16px;

  &.active {
    color: #245cd4;
    background-color: rgb(240, 248, 255);
  }
}
