@import 'utils';

.dynamic-width {
  width: 0;
  transition: all 0.2s ease-in-out;

  &.no-animation {
    transition: all 0s;
  }
}

.dynamic-height {
  height: 0;
  transition: all 0.2s ease-in-out;

  &.no-animation {
    transition: all 0s;
  }
}

.right-drawer {
  border-left: 1px solid $drawerBorder;
  background: $white;

  @apply h-full relative;

  .horizontal-resize {
    cursor: ew-resize;
    height: 100%;
    position: absolute;
    top: 0;
    width: 3px;
    z-index: 1;
    border: 1px solid $textColor5;
  }
}

.bottom-drawer {
  border-top: 1px solid $drawerBorder;
  background: $white;

  @apply relative;

  .vertical-resize {
    cursor: ns-resize;
    width: 100%;
    position: absolute;
    top: 0;
    height: 3px;
    z-index: 1;
    border: 1px solid $textColor5;
  }
}

.drawer-header {
  background: $drawerHeaderBackground;

  @apply px-3 py-1 flex justify-end items-center gap-3;

  .icon {
    @apply cursor-pointer px-1 w-7 h-7 flex justify-center items-center;

    &.no-action {
      cursor: auto;
    }
  }
}

.drawer-content {
  padding: 0.75rem;
  height: calc(100% - 50px);
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
