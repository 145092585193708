@import 'utils';

.select-w {
  width: 137px;

  .ant-input-suffix {
    color: $textColor2;
  }
}

.select-wb {
  width: 150px;

  .ant-input-suffix {
    color: $textColor2;
  }
}
