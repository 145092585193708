@import 'utils';

.venues-table {
  width: auto;
  background: $drawerHeaderBackground;
  height: 100%;
  overflow-y: hidden;

  @apply relative my-2 px-1 py-3 overflow-x-auto shadow-md sm:rounded-lg;

  .table-header {
    background-color: $bgColor1;

    tr:first-child th:first-child {
      border-top-left-radius: 0.75rem;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 0.75rem;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }
}
