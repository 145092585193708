@import 'utils';

.empty-data {
  .title {
    color: $textColor4;
    font-size: 1rem;
    font-weight: 590;
    line-height: 24px;
    background: inherit !important;
    border-bottom: none !important;
    padding: 0 !important;
  }

  .subtitle {
    color: $textColor2;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .btn-default {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }
}
